import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import clientAxios from '../../clients/clientAxios';
import { handleKeyDown } from '../../helpers/handleKeyDown';
import {
  handleInputValue,
  handleMaxInputValue,
} from '../../helpers/handleInputValue';

const PaymentPlanEditGeneral = ({ planData, setFetchTrigger }) => {
  const [show, setShow] = useState(false);
  const [planUpdated, setPlanUpdated] = useState(planData);
  const [isLoading, setIsLoading] = useState(false);

  const percentajeInput = useRef();
  const priceInput = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChangePlan = (fieldName, value) => {
    setPlanUpdated((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };
  const handleSaveChangesPlan = async () => {
    try {
      setIsLoading(true);
      await clientAxios.patch(`/planes/${planData._id}`, planUpdated);
      setFetchTrigger((prevState) => !prevState);
      notify.show('Plan modificado correctamente', 'success', 5000);
      handleClose();
    } catch {
      notify.show('No se pudo modificar el plan', 'error', 5000);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPlanUpdated(planData);
  }, [planData]);

  return (
    <>
      <Button variant='success' onClick={handleShow}>
        Editar
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{planUpdated.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type='text'
                value={planUpdated.name}
                name='name'
                onChange={(e) =>
                  handleChangePlan(e.target.name, e.target.value)
                }
                placeholder='Nombre del Plan'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type='text'
                name='description'
                value={planUpdated.description}
                onChange={(e) =>
                  handleChangePlan(e.target.name, e.target.value)
                }
                placeholder='Breve descripción'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Precio</Form.Label>
              <Form.Control
                type='number'
                name='totalAmount'
                ref={priceInput}
                min={0}
                onInput={handleInputValue}
                onKeyDown={handleKeyDown}
                value={planUpdated.totalAmount}
                onChange={(e) =>
                  handleChangePlan(e.target.name, e.target.value)
                }
                placeholder='Precio del Plan'
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Porcentaje de descuento %</Form.Label>
              <Form.Control
                type='number'
                name='percentageOfDiscount'
                ref={percentajeInput}
                max={100}
                min={0}
                onInput={handleMaxInputValue}
                onKeyDown={handleKeyDown}
                value={
                  planUpdated.percentageOfDiscount &&
                  planUpdated.percentageOfDiscount
                }
                onChange={(e) =>
                  handleChangePlan(e.target.name, e.target.value)
                }
                placeholder='Descuento %'
              />
            </Form.Group>
            <Button
              variant='success'
              type='button'
              onClick={() => handleSaveChangesPlan()}
            >
              {isLoading ? 'Cargando...' : 'Editar'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentPlanEditGeneral;
