import clientAxios from "../clients/clientAxios";


export const searchClassroomByName = async (setIsLoading, setSuggestions, searchValue, showAll) => {
    try {
      setIsLoading(true);
      let query = `?search=partial${showAll ? '&all=true' : ''}`;
      const { data } = await clientAxios(
        `/classroom/name/${searchValue}${query}`
      );
      setSuggestions(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    };
