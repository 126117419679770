import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const PromoteModal = ({ show, onHide, OnPromote, isProcessing, processedStudents }) => {

  const handlePromote = async () => {
    OnPromote();
  };

  const handleClose = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Promoción de Estudiantes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isProcessing ? (
          <div className="text-center py-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Procesando...</span>
            </div>
            <p className="mt-3">Procesando estudiantes...</p>
          </div>
        ) : processedStudents?.success?.length > 0 || processedStudents?.failed?.length > 0 ? (
          <Row>
            <Col md={6}>
              <h5 className="text-success">Procesados exitosamente</h5>
              <ul className="list-unstyled">
                {processedStudents?.success.map((email, idx) => (
                  <li key={`success-${idx}`}>{email}</li>
                ))}
              </ul>
            </Col>
            <Col md={6}>
              <h5 className="text-danger">Fallidos</h5>
              <ul className="list-unstyled">
                {processedStudents?.failed.map((email, idx) => (
                  <li key={`failed-${idx}`}>{email}</li>
                ))}
              </ul>
            </Col>
          </Row>
        ) : (
          <p>¿Está seguro que desea promover a los estudiantes seleccionados?</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isProcessing && (
          <>
            <Button variant="secondary" onClick={onHide}>
              Cerrar
            </Button>
            {!processedStudents?.success?.length && !processedStudents?.failed?.length && (
              <Button variant="primary" onClick={handlePromote}>
                Promover
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PromoteModal;