import React, { useEffect, useState } from 'react';
import { searchClassroomByName } from '../../helpers/searchClassRoomByName';
import { Button, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import PromoteModal from './PromoteModal';
import clientAxios from '../../clients/clientAxios';

const PromoteStudents = ({ commission }) => {
  const [searchValue, setSearchValue] = useState('');
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newClassroom, setNewClassroom] = useState('');
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processedStudents, setProcessedStudents] = useState({
    success: [],
    failed: []
  });

  const handleChangeSearchValue = (event) => {
    setSearchValue(event?.target?.value);
    if (event?.target?.value.length >= 2) {
      setSuggestionsVisible(true);
    } else {
      setSuggestionsVisible(false);
    }
  };

  const handleClearInput = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (searchValue?.length >= 2) {
      searchClassroomByName(setIsLoading, setSuggestions, searchValue, true);
    }
  }, [searchValue]);

  const handleNewCommission = (newClassroom) => {
    if (newClassroom?._id === commission?._id) {
      notify.show('No puedes promover a la misma comisión', 'error', 3000);
      return;
    }
    setSearchValue(newClassroom?.classroom);
    setNewClassroom(newClassroom?._id);
    setSuggestionsVisible(false);
  };

  const handlePromote = async () => {
  setIsProcessing(true);
  const user = JSON.parse(localStorage.getItem('userdata'));
  try {
    const {data} = await clientAxios.post(
      `/classroom/promote/${commission?._id}`,
      { newClassroomId: newClassroom, userId: user?.id },
    );

    setProcessedStudents({
      success: data?.success || [],
      failed: data?.failed || []
    });
  } catch (error) {
    notify.show('Error al procesar la solicitud', 'error', 3000);
    setProcessedStudents({
      success: [],
      failed: ['Error al procesar la solicitud']
    });
  } finally {
    setIsProcessing(false);
    handleClearInput();
  }
  };

  const handleCloseModal = () => {
    setShowPromoteModal(false);
    setProcessedStudents({
      success: [],
      failed: []
    });
  }

  return (
    <div>
      <span className="lead">Promover estudiantes</span>
      <div className="search-bar-container mt-3">
        <span>Seleccionar comisión</span>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>
              <i className="fa fa-search"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="text"
            placeholder="Buscar comision..."
            value={searchValue}
            onChange={handleChangeSearchValue}
            disabled={!commission?._id}
          />
          {suggestionsVisible && (
            <ListGroup 
              className="suggestions-list"
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                zIndex: 1000
              }}
            >
              <ListGroup.Item>
                {isLoading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="sr-only">Cargando...</span>
                    </div>
                  </div>
                ) : (
                  suggestions?.map((suggestion, index) => (
                    <ListGroup.Item 
                      key={suggestion?._id}
                      onClick={() => handleNewCommission(suggestion)}
                      style={{ cursor: 'pointer' }}
                    >
                      {suggestion?.classroom}
                    </ListGroup.Item>
                  ))
                )}
              </ListGroup.Item>
            </ListGroup>
          )}
        </InputGroup>
      </div>
      <div>
        <Button
          className="mt-2"
          variant="info"
          type="button"
          disabled={!newClassroom || !commission?._id}
          onClick={()=> setShowPromoteModal(true)}
        >
          <i className="fas fa-chevron-circle-up mr-2" />
          Promover alumnos de comisión
        </Button>
      </div>
      <PromoteModal
        show={showPromoteModal}
        onHide={handleCloseModal}
        OnPromote={handlePromote}
        isProcessing={isProcessing}
        processedStudents={processedStudents}
      />
    </div>
  );
};

export default PromoteStudents;