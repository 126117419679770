import React, { useEffect, useState } from 'react';
import clientAxios from '../../../clients/clientAxios';
import { Button, Dropdown, Modal, Table } from 'react-bootstrap';
import { notify } from 'react-notify-toast';
import LoadingScreen from '../../../Layout/LoadingScreen';
import moment from 'moment';
import 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { TIMEZONE } from '../../../helpers/argenitianTimeZone';
import { userNormalizationForCampus } from '../../../helpers/studentNormalization';

const Subscriptions = () => {
  const [studentsData, setStudentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState(
    'Subscripciones Banco / Efectivo'
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const handleOpenModal = (student) => {
    setSelectedStudent(student);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedStudent(null);
    setPaymentMethod(null);
  };
  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const history = useHistory();

  const optionsDate = [
    { value: 2, label: '2 días' },
    { value: 3, label: '3 días' },
    { value: 7, label: '1 semana' },
    { value: 14, label: '2 semanas' },
  ];

  const [endDate, setEndDate] = useState(
    optionsDate.find((option) => option.value === 2)
  );

  const SUBSCRIPTION_STATUS = {
    PENDING: 'Pendiente',
    ACTIVE: 'Activa',
    CANCELLED: 'Cancelada',
  };

  const handleSelectSubscriptionType = (e) => {
    setSubscriptionType(e);
  };

  const handleSelectEndDate = (e) => {
    const selectedOption = optionsDate.find(
      (option) => option.value === parseInt(e)
    );
    setEndDate(selectedOption);
  };

  const calculateDate = (value) => {
    const currentDate = moment();
    const currentCalculated = currentDate.subtract(value.value, 'days');
    return currentCalculated.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  };

  const getSubscriptions = async () => {
    let subs;
    try {
      setIsLoading(true);
      const calculatedDate = calculateDate(endDate);
      const queryParams = new URLSearchParams({
        endDate: calculatedDate,
      });
      if (subscriptionType === 'Subscripciones Banco / Efectivo') {
        queryParams.append('mode', 'precheck');
        subs = await clientAxios.get(`/students/subscriptions?${queryParams}`);
      } else {
        subs = await clientAxios.get(`/students/subscriptions?${queryParams}`);
      }
      setStudentsData(subs?.data);
      setIsLoading(false);
    } catch {
      notify.show(
        'Ha ocurrido un error al intentar traer las subscripciónes.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const createFullStackUser = async (student) => {
    student.firstname = student.name;
    const campusUser = userNormalizationForCampus(student);
    try {
      await clientAxios.post(`/campus/user/`, campusUser);
    } catch (error) {
      if (error.response.data.code !== 501) {
        notify.show(
          'Ha ocurrido un error al intentar crear el usuario.',
          'error'
        );
      }
    }
  };

  const handleSubscription = async (payload, method) => {
    setIsLoading(true);
    createFullStackUser(payload);
    try {
      const paymentUpdateResponse = await clientAxios.put(
        `/sellLogs/${payload._id}/payment-method`,
        { payment_method: method }
      );
      if (
        paymentUpdateResponse?.data?.message !==
        'Payment method updated successfully'
      ) {
        console.error('Error al actualizar el método de pago');
      }
      const response = await clientAxios.post('/students/', payload);
      if (response?.data?.message.includes('E-mail ya registrado')) {
        notify.show(response.data.message, 'error');
      } else {
        history.push(`/students/details/${response?.data?.id}`);
      }
    } catch (error) {
      notify.show(
        `Ha ocurrido un error al intentar inscribir al estudiante. ${error}`,
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChangeSubscriptionState = async (
    studentId,
    selectedValue
  ) => {
    try {
      const payload = {
        subscriptionPending: selectedValue === SUBSCRIPTION_STATUS.PENDING,
      };
      const response = await clientAxios.patch(
        `/students/subscriptions/${studentId}`,
        payload
      );
      if (response?.data.success) {
        notify.show('Suscripción actualizada exitosamente.', 'success');
        getSubscriptions();
      } else {
        notify.show('Hubo un problema actualizando la suscripción.', 'error');
      }
    } catch (error) {
      notify.show('Error en la actualización de la suscripción.', 'error');
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, [subscriptionType, endDate]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="min-vh-100">
          <div className="ml-3 d-flex">
            <Dropdown onSelect={handleSelectSubscriptionType}>
              <Dropdown.Toggle variant="dark" id="dropdown-select-subscription">
                {subscriptionType}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="Subscripciones Banco / Efectivo">
                  Subscripciones Banco / Efectivo
                </Dropdown.Item>
                <Dropdown.Item eventKey="Subscripcíones">
                  Subscripciónes
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="ml-3" onSelect={handleSelectEndDate}>
              <Dropdown.Toggle variant="dark" id="dropdown-elect-days">
                {endDate.label}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {optionsDate.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    eventKey={option.value.toString()}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="d-flex justify-content-center my-5 table-responsive">
            <Table striped bordered hover variant="dark" className="w-75">
              <thead>
                <tr>
                  <th className="text-center">Fecha</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">Nombre y Apellido</th>
                  <th className="text-center">Comisión</th>
                  <th className="text-center">Email</th>
                  <th className="text-center">Telefono</th>
                  {subscriptionType === 'Subscripciones Banco / Efectivo' && (
                    <th className="text-center">Acciones</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {studentsData &&
                  studentsData.map((student, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        {moment(student?.updatedAt)
                          .tz(TIMEZONE)
                          .format('DD/MM/YYYY HH:MM')}
                      </td>
                      <td className="text-center">
                        <select
                          value={
                            student?.subscriptionPending ? 'Pendiente' : 'Ok'
                          }
                          onChange={(e) =>
                            handleSelectChangeSubscriptionState(
                              student._id,
                              e.target.value
                            )
                          }
                        >
                          <option value="Pendiente">Pendiente</option>
                          <option value="Ok">Ok</option>
                        </select>
                      </td>
                      <td className="text-center">
                        {student?.name} {student?.surname}
                      </td>
                      <td className="text-center">{student?.classroom}</td>
                      <td className="text-center">{student?.email}</td>
                      <td className="text-center">{student?.phone}</td>
                      {subscriptionType ===
                        'Subscripciones Banco / Efectivo' && (
                        <td className="text-center">
                          <Button
                            variant="primary"
                            onClick={() => handleOpenModal(student)}
                          >
                            Inscribir
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar Método de Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Selecciona el método de pago:</p>
          <div className="payment-options">
            <div className="payment-option">
              <input
                type="radio"
                id="bank"
                name="paymentMethod"
                value="bank"
                checked={paymentMethod === 'bank'}
                onChange={() => handlePaymentMethodChange('bank')}
                style={{ marginRight: '10px' }}
              />
              <label htmlFor="bank">Transferencia Bancaria</label>
            </div>
            <div className="payment-option">
              <input
                type="radio"
                id="cash"
                name="paymentMethod"
                value="cash"
                checked={paymentMethod === 'cash'}
                onChange={() => handlePaymentMethodChange('cash')}
                style={{ marginRight: '10px' }}
              />
              <label htmlFor="cash">Efectivo</label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (paymentMethod) {
                handleSubscription(selectedStudent, paymentMethod);
                handleCloseModal();
              } else {
                notify.show('Debe seleccionar un método de pago', 'error');
              }
            }}
            disabled={!paymentMethod}
          >
            Confirmar Inscripción
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Subscriptions;
