export const availableCourses = [
  'FullStack - Presencial (9 Meses)',
  'FullStack - SemiPresencial (9 meses)',
  'FullStack - SemiPresencial (6 meses)',
  'FullStack - Corto (6 meses)',
  'FullStack - Online (6 meses)',
  'Especialización ReactJS (3 meses)',
  'Especialización node.js',
  'Especialización Online',
  'Marketing',
  'Marketing 2020',
  'English',
  'Especialización Backend (3 meses)',
  'Creación y Diseño de páginas web - Carrera Fullstack (3 meses)',
  'Desarrollo web con React - Carrera Fullstack (3 meses)',
  'Backend - Integración de bases de datos en aplicaciones web - Carrera Fullstack (3 meses)',
  'Especialización Frontend con React - Carrera Fullstack (3 meses)',
  'Desarrollo de aplicaciones móviles - Carrera Fullstack (3 meses)',
  'Especialización en Backend con Node.js - Carrera Fullstack (3 meses)',
  'Testing con Node.js - Carrera Fullstack (3 meses)',
  'Rolling Kids 8 a 9 años (1.5 meses)',
  'Rolling Kids 10 a 12 años (1.5 meses)',
  'Rolling Kids 13 a 15 años (1.5 meses)',
  'Next.js (3 meses)',
];

export const availableCareer = ['Carrera Fullstack'];
export const installments = [12, 12, 9, 9, 9, 3, 3, 6, 9];
export const availableHours = [
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];
export const legacyEnglishClassroom = [
  '1',
  '1-upper',
  '1D',
  '2',
  '2-upper',
  '2D',
  '3',
  '3-intermediate',
  '3D',
  '4',
  '4-intermediate',
  '4D',
  '5',
  '5-begginer',
  '5D',
  '6',
  '6D',
  '7',
  '7D',
  '8',
  '8D',
];
