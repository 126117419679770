import React, { useContext, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";
import AdminNav from "./links/AdminNav";
import CommunicationsNav from "./links/CommunicationsNav";
import MentorNav from "./links/MentorNav";
import StatisticsNav from "./links/StatisticsNav";
import UserAdministration from "./UserAdministration";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { USER_LEVEL_MENTOR_STATISTICS, USER_LEVEL_STATISTICS, USER_LEVEL_MENTOR, USER_LEVEL_COMMUNICATION, USER_LEVEL_ADMIN } from "../../constants";
import preval from "preval.macro";
import { notify } from "react-notify-toast";
const buildTimeStamp = preval`module.exports = new Date().toLocaleString()`;

export default function Navigation({ errorPage }: { errorPage: Boolean }) {
  const history = useHistory();
  const { logout } = useContext(UserContext);
  const user = useContext(UserContext);
  const location = useLocation();

  const isTokenExpired = (token: string): boolean => {
    try {
      const base64Url = token.split('.')[1];
      if (!base64Url) return true;

      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(window.atob(base64));

      if (!payload.exp) return false;

      const currentTime = Math.floor(Date.now() / 1000);
      return payload.exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  const checkTokenAndLogout = () => {
    const token = localStorage.getItem("JWT");
    if (!token) return;
    if (isTokenExpired(token)) {
      localStorage.clear();
      notify.show('Usuario inválido o sesión expirada, vuelva a ingresar.', 'error');
      logout();
      history.push('/');
      return false;
    }
    return true;
  };

  useEffect(() => {
    checkTokenAndLogout();
  }, []);

  useEffect(() => {
    checkTokenAndLogout();
  }, [location.pathname]);

  let links = <div />;
  let title =
    window.location.hostname === "mentors.rollingcodeschool.com"
      ? "Mentor's Dashboard" :
      user.level === USER_LEVEL_STATISTICS
        ? "Statistic's Dashboard"
        : user.level === USER_LEVEL_MENTOR || user.level === USER_LEVEL_MENTOR_STATISTICS
          ? "Mentor's Dashboard"
          : user.level < USER_LEVEL_MENTOR
            ? "Student Manager"
            : "";

  if (user.level === USER_LEVEL_ADMIN) {
    links = <AdminNav />;
  } else if (user.level === USER_LEVEL_COMMUNICATION) {
    links = <CommunicationsNav />;
  } else if (user.level === USER_LEVEL_MENTOR || user.level === USER_LEVEL_MENTOR_STATISTICS) {
    links = <MentorNav />;
  } else if (user.level === USER_LEVEL_STATISTICS) {
    links = <StatisticsNav />;
  } else {
    links = <div />;
  }

  const popover = (
    <Popover id="build">
      <Popover.Title as="h3">Last Deploy - Date:</Popover.Title>
      <Popover.Content>
        {buildTimeStamp}
      </Popover.Content>
    </Popover>
  );

  return (
    <Navbar
      collapseOnSelect={true}
      expand="lg"
      bg="dark"
      variant="dark"
      className={errorPage ? "d-none" : ""}
    >
      <Link to="/">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={popover}
        >
          <Navbar.Brand className="text-dark text-decoration-none">
            <img
              src={process.env.PUBLIC_URL + "/rclogowhite.png"}
              width="180"
              alt="RollingCode"
            />
            <p className="mb-2 lead text-center text-white">{title}</p>
          </Navbar.Brand>
        </OverlayTrigger>
      </Link>
      <Navbar.Toggle />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">{links}</Nav>
        <UserAdministration />
      </Navbar.Collapse>
    </Navbar>
  );
}