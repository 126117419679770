import React, { useEffect, useState } from 'react';
import ConfirmStudentDelete from './modals/ConfirmStudentDelete';
import LoadingScreen from '../../../Layout/LoadingScreen';
import PaymentHistorial from './modals/PaymentHistorial';
import StudentCommissions from './modals/StudentCommissions';
import CampusInfo from './modals/CampusInfo';
import CertificateIssuer from './modals/CertificateIssuer';
import { Container, Row, Col } from 'react-bootstrap';
import AdditionalData from './cards/AdditionalData';
import PrincipalData from './cards/PrincipalData';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CourseList from './cards/CourseList';
import { notify } from 'react-notify-toast';
import StudentName from './StudentName';
import Buttons from './Buttons';
import clientAxios from '../../../clients/clientAxios';
import moment from 'moment';

export default function StudentDetail() {
  const { id } = useParams(),
    history = useHistory(),
    [showPaymentHistorialModal, setShowPaymentHistorialModal] = useState(false),
    [showCampusInfoModal, setShowCampusInfoModal] = useState(false),
    [showStudentCommissionslModal, setShowStudentCommissionslModal] =
      useState(false),
    [campusUserFromWS, setcampusUserFromWS] = useState(null),
    [statusCampusUSer, setStatusCampusUSer] = useState('secondary'),
    [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false),
    [showCertificateModal, setShowCertificateModal] = useState(false),
    [hasLoaded, setHasLoaded] = useState(false),
    [student, setStudent] = useState({
      name: '',
      location: '',
      birthDate: '',
      dni: 0,
      address: '',
      country: '',
      region: '',
      phone: 0,
      phoneAlt: 0,
      email: '',
      currentJob: '',
      itKnowleadge: '',
      englishKnowleadge: '',
      codeKnowleadge: '',
      availableSat: false,
      ownNotebook: false,
      contactOrigin: '',
      availableTimeFrom: '',
      availableTimeTo: '',
      scholarship: '',
      preferredSite: '',
      informativeTalkDate: '',
      course: '',
      classroom: '',
      isActive: '',
      price: '',
      englishClassroom: '',
      createdAt: '',
      createdBy: '',
      modifiedAt: '',
      modifiedBy: '',
      welcomeEmail: '',
      pauseClassActivity: false,
    }),
    [certificate, setCertificate] = useState({
      items: [],
      loading: true,
    });
  const [pauseClassActivity, setPauseClassActivity] = useState(
    student.pauseClassActivity
  );
  const [changeClassActivity, setChangeClassActivity] = useState(false);
  const [skipAutoSuspendFlag, setSkipAutoSuspendFlag] = useState(false);

  useEffect(() => {
    getStudentData();

    //eslint-disable-next-line
  }, [id, pauseClassActivity, skipAutoSuspendFlag]);

  const getStudentData = () => {
    clientAxios
      .get(`/students/${id}`)
      .then(({ data }) => {
        getCertificateBadge(data.response.email);
        setStudent({
          ...data.response,
          firstname: data.response.name,
          name: `${data.response.name} ${data.response.surname}`,
          createdBy: data.response.createdBy
            ? data.response.createdBy.name
            : '',
          modifiedBy: data.response.lastUpdateBy
            ? data.response.lastUpdateBy.name
            : '',
          welcomeEmail: !data.response.welcomeEmail
            ? 'Pendiente...'
            : data.response.welcomeEmail
            ? 'Enviado'
            : 'No enviado',
          additionalNotes: data.response.additionalNotes,
          classroomData: data.response?.classroomData,
          pauseInitialDate: data.response?.pauseInitialDate,
        });
        setHasLoaded(true);
      })
      .catch((err) => {
        notify.show(
          err && err?.response ? err.response?.data?.message : err?.message,
          'error'
        );
        history.push('/');
      });
  };

  const getCertificateBadge = (email) => {
    clientAxios
      .get(`/certificate/${email}`)
      .then(({ data }) => {
        if (data && data.response && data.response.length > 0) {
          setCertificate({
            items: data.response,
            loading: false,
          });
        } else {
          setCertificate({
            loading: false,
            items: [],
          });
        }
      })
      .catch((err) => {
        notify.show(
          err.response ? err.response.data.message : err.message,
          'error'
        );
        history.push('/');
      });
  };

  const handlePauseClassActivity = async () => {
    try {
      setChangeClassActivity((prev) => !prev);
      setPauseClassActivity((prev) => !prev);
      await clientAxios.patch(`/students/${student._id}`, {
        pauseClassActivity: !pauseClassActivity,
        pauseInitialDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      });
      setPauseClassActivity((prev) => prev);
    } catch (error) {
      notify.show(
        'Ha ocurrido un error. Contacta con un administrador.',
        'error'
      );
    } finally {
      setChangeClassActivity((prev) => !prev);
    }
  };
  const handleSkipAutoSuspendChange = async () => {
    try {
      const status = student.skipAutoSuspend
        ? null
        : moment().format('YYYY-MM-DD HH:mm:ss');
      setSkipAutoSuspendFlag(true);
      await clientAxios.patch(`/students/${student._id}`, {
        skipAutoSuspend: status,
      });
      notify.show('Exĭtus 🙌', 'success');
    } catch (error) {
      notify.show(
        'Ha ocurrido un error. Contacta con un administrador.',
        'error'
      );
    } finally {
      setSkipAutoSuspendFlag(false);
    }
  };

  if (hasLoaded)
    return (
      <Container>
        {/* Modals */}

        <PaymentHistorial
          show={showPaymentHistorialModal}
          onHide={() =>
            setShowPaymentHistorialModal(!showPaymentHistorialModal)
          }
          skipAutoSuspend={student.skipAutoSuspend}
          studentId={id}
        />

        <CampusInfo
          setStatusCampusUSer={setStatusCampusUSer}
          show={showCampusInfoModal}
          onHide={() => setShowCampusInfoModal(!setShowCampusInfoModal)}
          student={student}
          setcampusUserFromWS={setcampusUserFromWS}
        />

        <CertificateIssuer
          setStatusCampusUSer={setStatusCampusUSer}
          show={showCertificateModal}
          onHide={() => setShowCertificateModal(!setShowCertificateModal)}
          student={student}
        />

        <ConfirmStudentDelete
          show={showConfirmDeleteModal}
          onHide={() => setShowConfirmDeleteModal(!showConfirmDeleteModal)}
          studentId={id}
        />

        {/* Content of screen */}
        <Row>
          <StudentName
            name={student.name}
            statusStudent={student.pauseClassActivity}
            dateOfTheLastPause={student.pauseInitialDate}
          />
          <Col />
          <Buttons
            id={id}
            campusUserId={student?.campusUserId}
            createdBy={student.createdBy}
            modifiedBy={student.modifiedBy}
            statusCampusUSer={statusCampusUSer}
            pauseClassActivity={student.pauseClassActivity}
            changeClassActivity={changeClassActivity}
            timeStamps={{
              createdAt: student.createdAt,
              modifiedAt: student.modifiedAt,
            }}
            skipAutoSuspend={student.skipAutoSuspend}
            skipAutoSuspendFlag={skipAutoSuspendFlag}
            togglePaymentHistorial={() =>
              setShowPaymentHistorialModal(!showPaymentHistorialModal)
            }
            toggleCampusInfo={() =>
              setShowCampusInfoModal(!showCampusInfoModal)
            }
            toggleCertificateModal={() =>
              setShowCertificateModal(!showCertificateModal)
            }
            toggleConfirmDeleteModal={() => {
              setShowConfirmDeleteModal(!showConfirmDeleteModal);
            }}
            pauseStudentButton={() => {
              handlePauseClassActivity();
            }}
            skipAutoSuspendChange={() => {
              handleSkipAutoSuspendChange();
            }}
          />
        </Row>
        <Row>
          <PrincipalData
            location={student.location}
            birthDate={student.birthDate}
            dni={student.dni}
            address={student.address}
            country={student.country}
            region={student.region}
            phone={student.phone}
            phoneAlt={student.phoneAlt}
            email={student.email}
            currentJob={student.currentJob}
          />
          <AdditionalData
            itKnowleadge={student.itKnowleadge}
            englishKnowleadge={student.englishKnowleadge}
            codeKnowleadge={student.codeKnowleadge}
            availableSat={student.availableSat}
            ownNotebook={student.ownNotebook}
            notebookModel={student.notebookModel}
            contactOrigin={student.contactOrigin}
            availableTimeFrom={student.availableTimeFrom}
            availableTimeTo={student.availableTimeTo}
            scholarship={student.scholarship}
            preferredSite={student.preferredSite}
            informativeTalkDate={student.informativeTalkDate}
            welcomeEmail={student.welcomeEmail}
            additionalNotes={student.additionalNotes}
          />
          <CourseList
            scholarship={
              Array.isArray(student?.scholarship)
                ? student?.scholarship?.filter(Boolean)
                : []
            }
            classroom={student.classroom.filter(Boolean)}
            isActive={student?.isActive?.filter(Boolean) || false}
            course={student.course.filter(Boolean)}
            certificate={certificate}
            fullClassRoom={student.classroomData}
            classroomActive={student.classroomActive}
            studentId={student._id}
            certificatesFromService={student?.certificates}
            student={student}
            getStudentData={getStudentData}
            campusUserFromWS={campusUserFromWS}
            hideLoadingLayout={setHasLoaded}
          />
        </Row>
      </Container>
    );
  else return <LoadingScreen />;
}
