import React, { Component } from 'react';
import { Form, Card, InputGroup, Row, Col } from 'react-bootstrap';
import { availableCourses } from '../../../Context/Courses';
import clientAxios from '../../../clients/clientAxios';
import './courseCard.css';

export default class CourseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: '',
      classroom: '',
      monthlyInstallments: null,
      price: '',
      isActive: '',
      downReason: ['', '', ''],
      otherDownReason: ['', '', ''],
      moduleDownReason: ['', '', ''],
      commissionChangedReason: ['', '', ''],
      otherCommissionChangedReason: ['', '', ''],
      moduleCommissionChangedReason: ['', '', ''],
      scholarship: false,
      changedInCommission: ['', '', ''],
      currency: 'ARS',
      plan: this.state?.planes[0]?.planId || {},
      showSpecialPlans: false,
    };
  }
  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps() {
    this.getData();
  }

  getData = () => {
    let selectedClassroom =
      typeof this.props.classroom === 'object'
        ? this.props.classroom[this.props.cardId]
        : this.props.classroom;
    let course = this.props.classroomList.find((cr) =>
      selectedClassroom !== '' ? cr.classroom === selectedClassroom : null
    );
    let selectedPlan =
      typeof this.props.plan === 'object'
        ? this.props.plan[this.props.cardId]
        : this.props.plan;

    if (!selectedPlan && this.state?.planes?.length > 0) {
      selectedPlan = this.state.planes[0];
    }
    let price =
      this.props.price !== null
        ? typeof this.props.price === 'object'
          ? this.props.price[this.props.cardId]
          : this.props.price
        : 0;
    let monthlyInstallments =
      typeof this.props.monthlyInstallments === 'object'
        ? this.props.monthlyInstallments[this.props.cardId]
        : this.props.monthlyInstallments;
    let isActive =
      typeof this.props.isActive === 'object'
        ? this.props.isActive[this.props.cardId]
        : this.props.isActive;
    let downReason =
      typeof this.props.downReason === 'object'
        ? this.props.downReason[this.props.cardId]
        : this.props.downReason;
    let otherDownReason =
      typeof this.props.otherDownReason === 'object'
        ? this.props.otherDownReason !== null
          ? this.props.otherDownReason[this.props.cardId]
          : ['', '', '']
        : this.props.otherDownReason;
    let commissionChangedReason =
      typeof this.props.commissionChangedReason === 'object'
        ? this.props.commissionChangedReason[this.props.cardId]
        : this.props.commissionChangedReason;
    let otherCommissionChangedReason =
      typeof this.props.otherCommissionChangedReason === 'object'
        ? this.props.otherCommissionChangedReason !== null
          ? this.props.otherCommissionChangedReason[this.props.cardId]
          : ['', '', '']
        : this.props.otherCommissionChangedReason;
    let moduleDownReason =
      typeof this.props.moduleDownReason === 'object'
        ? this.props.moduleDownReason !== null
          ? this.props.moduleDownReason[this.props.cardId]
          : ['', '', '']
        : this.props.moduleDownReason;
    let moduleCommissionChangedReason =
      typeof this.props.moduleCommissionChangedReason === 'object'
        ? this.props.moduleCommissionChangedReason !== null
          ? this.props.moduleCommissionChangedReason[this.props.cardId]
          : ['', '', '']
        : this.props.moduleCommissionChangedReason;
    let changedInCommission =
      typeof this.props.changedInCommission === 'object'
        ? this.props.changedInCommission !== null
          ? this.props.changedInCommission[this.props.cardId]
          : ['', '', '']
        : this.props.changedInCommission;
    let currency =
      typeof this.props.currency === 'object'
        ? this.props.currency[this.props.cardId]
        : this.props.currency;

    if (!currency) {
      currency = 'ARS';
    }

    this.setState({
      classroom: selectedClassroom,
      monthlyInstallments,
      price,
      isActive,
      cardId: this.props.cardId,
      scholarship: Boolean(this.props.scholarship[this.props.cardId]),
      course: course ? course.course : '',
      downReason,
      otherDownReason,
      moduleDownReason,
      commissionChangedReason,
      otherCommissionChangedReason,
      moduleCommissionChangedReason,
      changedInCommission,
      currency,
      plan: selectedPlan?.planId,
    });

    if (!this.state.planes) {
      clientAxios.get('/planes').then(
        ({ data }) => {
          this.setState({ planes: data });
          //this.setState({ plan: data[0]._id });
          this.setState({ showSpecialPlans: this.checkIsSpecialPlan() });
          if (
            !this.state.price ||
            (this.state.price === '' && data?.length > 0)
          ) {
            this.setState({ price: data[0].priceSchema[0] });
            this.setState({
              monthlyInstallments: data[0].priceSchema.length,
            });
            this.props.handleSave(this.state, '0');
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.classroom[this.props.cardId] !== this.state.classroom) {
      this.props.handleSave(this.state);
    }
  }

  closeCard = () => {
    this.props.handleClose();
  };

  handlePopulatePriceFromPlanSchema = (id) => {
    if (this.state.planes?.length > 0) {
      const result = this.state.planes.find(({ _id }) => _id === id);
      return result ? this.setState({ price: result.priceSchema[0] }) : null;
    }
  };

  handleChange = (obj) => {
    let { name, value, type, checked } = obj.target;
    let planChanged = {};
    let data = this.props.classroomList.find((cr) =>
      value !== '' ? cr.classroom === value : null
    );

    this.setState({ changedInCommission: this.state.classroom });

    if (name === 'plan') {
      //planChanged = this.state.plan;
      //planChanged.planId = value;
      this.handlePopulatePriceFromPlanSchema(value);
      this.setState({ plan: value });
    }
    if (name === 'classroom') {
      let processedCourse = data.course ? data.course : null;
      //planChanged = this.state.plan;
      //planChanged.courseId = data?._id;
      //planChanged.classroom = data?.classroom;

      //this.setState(plan: );

      this.setState({ [name]: value, course: processedCourse }, () => {
        this.props.handleSave(this.state);
      });
    } else if (type === 'checkbox') {
      this.setState({ [name]: checked }, () => {
        this.props.handleSave(this.state);
      });
    } else if (name === 'isActive') {
      this.setState({ [name]: value }, () => {
        this.props.handleSave(this.state);
      });
    } else {
      this.setState({ [name]: value }, () => {
        this.props.handleSave(this.state);
      });
    }
  };

  toggleShowSpecialPlans = () => {
    this.setState((prevState) => ({
      showSpecialPlans: !prevState.showSpecialPlans,
    }));
  };
  checkIsSpecialPlan() {
    const { planes, plan } = this.state;
    const selectedPlan = planes?.filter((item) => item._id === plan)?.[0];
    return selectedPlan?.specialPlan || false;
  }

  render() {
    return (
      <Card className='p-3 mx-3 mb-3 shadow'>
        <Form.Group>
          <Form.Label className='w-100'>
            <Row>
              <Col>
                Curso<strong className='text-danger'>*</strong>
              </Col>
              <Col className='text-right'>
                <i
                  onClick={this.closeCard}
                  className='fas fa-times text-danger'
                />
              </Col>
            </Row>
          </Form.Label>

          <Form.Control
            as='select'
            name='course'
            onChange={this.handleChange}
            disabled
            value={this.state.course}
          >
            <option value=''>Esta comisión no tiene un curso asignado</option>
            {availableCourses.map((course, index) => (
              <option key={index} value={course}>
                {course}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col className='d-flex align-items-center'>
              <Form.Label className='pe-2 mb-0'>
                Mostrar solo planes especiales
              </Form.Label>
              <Form.Check
                type={'checkbox'}
                className='mx-2 custom-checkbox'
                onChange={this.toggleShowSpecialPlans}
                checked={this.state.showSpecialPlans}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Comisión<strong className='text-danger'>*</strong>
          </Form.Label>

          <Form.Control
            as='select'
            name='classroom'
            onChange={this.handleChange}
            value={this.state.classroom}
          >
            <option value=''>Seleccione una opción...</option>
            {this.props.classroomList.map((item, i) => (
              <option data-index={item.course} key={i} value={item.classroom}>
                {item.classroom}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Plan de Pagos<strong className='text-danger'>*</strong>
          </Form.Label>
          <Form.Control
            as='select'
            name='plan'
            onChange={this.handleChange}
            value={this.state.plan}
          >
            <option value=''>Seleccione un plan...</option>
            {this.state.planes?.map((item, i) => {
              if (
                (item?.specialPlan !== true &&
                  this.state.showSpecialPlans === false) ||
                (item?.specialPlan === true &&
                  this.state.showSpecialPlans === true)
              ) {
                return (
                  <option data-index={item._id} key={i} value={item._id}>
                    {item?.description}
                  </option>
                );
              }
              return null;
            })}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Cantidad de cuotas</Form.Label>
          <Form.Control
            min={1}
            type='number'
            name='monthlyInstallments'
            required
            onChange={this.handleChange}
            value={this.state.monthlyInstallments}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Tipo de moneda</Form.Label>

          <Form.Control
            as='select'
            name='currency'
            onChange={this.handleChange}
            value={this.state.currency}
          >
            <option value='' hidden>
              Seleccione una moneda...
            </option>
            <option data-index={'ARS'} key={'ARS'} value={'ARS'}>
              ARS
            </option>
            <option data-index={'USD'} key={'USD'} value={'USD'}>
              USD
            </option>
            <option data-index={'EUR'} key={'EUR'} value={'EUR'}>
              EUR
            </option>
          </Form.Control>
        </Form.Group>

        <Form.Group className='d-none'>
          <Form.Label>Precio</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>$</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              min={1}
              type='number'
              name='price'
              onChange={this.handleChange}
              value={this.state.price}
              step={0.01}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Estado<strong className='text-danger'>*</strong>
          </Form.Label>
          <Form.Control
            as='select'
            name='isActive'
            onChange={this.handleChange}
            value={this.state.isActive}
            required
            disabled={!this.state.classroom}
          >
            <option value='' hidden>
              Seleccione una comisión...
            </option>
            <option value='prepay'>Señado</option>
            <option value='up'>Activo</option>
            <option value='posibleDown'>Posible baja</option>
            <option value='down'>Baja</option>
            <option value='done'>Finalizo cursado</option>
            <option value='commissionChanged'>Cambio de comisión</option>
          </Form.Control>
          {this.state.isActive === 'down' ? (
            <>
              <Form.Label className='mt-3'>Motivo de la baja</Form.Label>
              <Form.Group>
                <Form.Control
                  as='select'
                  type='text'
                  name='downReason'
                  onChange={this.handleChange}
                  value={this.state.downReason}
                >
                  <option value='' hidden>
                    Escriba el motivo de la baja
                  </option>
                  <option value='lackTime'>Falta de tiempo</option>
                  <option value='disease'>Enfermedad</option>
                  <option value='workProblems'>Laborales</option>
                  <option value='economicProblems'>Económicas</option>
                  <option value='disagreementDictation'>
                    Disconformidad con el dictado
                  </option>
                  <option value='others'>Otros</option>
                </Form.Control>
              </Form.Group>
              {this.state.downReason === 'others' ? (
                <InputGroup>
                  <Form.Control
                    type='text'
                    placeholder='Ingrese el motivo'
                    name='otherDownReason'
                    onChange={this.handleChange}
                    value={this.state.otherDownReason}
                    className='mb-3'
                  />
                </InputGroup>
              ) : null}
              <Form.Label>Modulo de la baja</Form.Label>
              <InputGroup>
                <Form.Control
                  type='text'
                  placeholder='Ingrese el modulo'
                  name='moduleDownReason'
                  onChange={this.handleChange}
                  value={this.state.moduleDownReason}
                />
              </InputGroup>
            </>
          ) : this.state.isActive === 'commissionChanged' ? (
            <>
              <Form.Label className='mt-3'>Motivo del cambio</Form.Label>
              <Form.Group>
                <Form.Control
                  as='select'
                  type='text'
                  name='commissionChangedReason'
                  onChange={this.handleChange}
                  value={this.state.commissionChangedReason}
                >
                  <option value='' hidden>
                    Escriba el motivo del cambio
                  </option>
                  <option value='lackTime'>Falta de tiempo</option>
                  <option value='disease'>Enfermedad</option>
                  <option value='workProblems'>Laborales</option>
                  <option value='economicProblems'>Económicas</option>
                  <option value='disagreementDictation'>
                    Disconformidad con el dictado
                  </option>
                  <option value='others'>Otros</option>
                </Form.Control>
              </Form.Group>
              {this.state.commissionChangedReason === 'others' ? (
                <InputGroup>
                  <Form.Control
                    type='text'
                    placeholder='Ingrese el motivo'
                    name='otherCommissionChangedReason'
                    onChange={this.handleChange}
                    value={this.state.otherCommissionChangedReason}
                    className='mb-3'
                  />
                </InputGroup>
              ) : null}
              <Form.Label>Modulo del cambio</Form.Label>
              <InputGroup>
                <Form.Control
                  type='text'
                  placeholder='Ingrese el modulo'
                  name='moduleCommissionChangedReason'
                  onChange={this.handleChange}
                  value={this.state.moduleCommissionChangedReason}
                />
              </InputGroup>
            </>
          ) : null}
        </Form.Group>

        <Form.Group className='border rounded p-2'>
          <Form.Check
            type='checkbox'
            checked={this.state.scholarship}
            onChange={this.handleChange}
            name='scholarship'
            label='Curso becado'
          />
        </Form.Group>
      </Card>
    );
  }
}
