// Importando librerías externas
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { UserContext } from '../../Context/UserContext';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { ClassroomList } from '../../Context/ClassroomListContext';
import CampusEnrollment from './CampusEnrollment';
import QuillEditor from './QuillEditor';
// Importando info
import { availableCourses } from '../../Context/Courses';
import Autocomplete from './Autocomplete';
import clientAxios from '../../clients/clientAxios';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export default function Info({ mentorList, commission, setCommission }) {
  const { updateClassRoomList } = useContext(ClassroomList);
  const [changes, setChanges] = useState(false);
  const [showCampusEnrollment, setShowCampusEnrollment] = useState(false);
  const user = useContext(UserContext);
  const [plans, setPlans] = useState(null);
  const [carreers, setCarreers] = useState(null);

  const [scheduleCopy, setScheduleCopy] = useState({
      schedule: [
        {
          day: '',
          since: '',
          to: '',
        },
      ],
    }),
    history = useHistory(),
    headerConfig = {
      headers: { 'auth-token': localStorage.getItem('JWT') || ' ' },
    },
    updateCommission = (obj) => {
      if (obj) {
        obj.preventDefault();
      }
      if (!commission.schedule) {
        commission.schedule = scheduleCopy.schedule;
      } else if (commission.schedule && commission.schedule.length === 0) {
        commission.schedule = scheduleCopy.schedule;
      }

      axios
        .put(
          `api/classroom/edit/${commission._id}`,
          { commission, changes },
          headerConfig
        )
        .then(({ data }) => {
          if (data.success) {
            notify.show(data.message, 'success');
            setChanges(false);
            updateClassRoomList();
          } else {
            notify.show(data.message, 'error');
          }
        })
        .catch((err) => {
          notify.show(err.response.data.message, 'error');
          history.push('/');
        });
    },
    // Manejar el cambio de valores en los inputs del form
    handleChange = (obj, index, origin) => {
      let { name, value } = obj.target;
      if (name === 'helper' && !value) {
        value = null;
      }
      if (name === 'course') {
        setChanges(true);
      }
      if (origin) {
        if (origin === 'schedule') {
          let scheduleLet = commission.schedule;
          scheduleLet[index] = { ...scheduleLet[index], [name]: value };
          setCommission({ ...commission, schedule: scheduleLet });
        } else if (origin === 'copy') {
          let scheduleCopyLet = scheduleCopy.schedule;
          scheduleCopyLet[index] = { ...scheduleCopyLet[index], [name]: value };
          setScheduleCopy({ ...scheduleCopy, schedule: scheduleCopyLet });
        }
      } else {
        setCommission({ ...commission, [name]: value });
      }
    },
    handleQuillEdit = (value) => {
      setCommission((prev) => {
        return {
          ...prev,
          courseSummary: value,
        };
      });
    },
    enrollToCourse = () => {},
    addDaytoSchedule = (type) => {
      if (type === 'schedule') {
        let schedulePropCopy = commission.schedule;
        schedulePropCopy.push({
          day: '',
          since: '',
          to: '',
        });
        setCommission({ ...commission, schedule: schedulePropCopy });
      } else if (type === 'copy') {
        let scheduleCopyState = scheduleCopy.schedule;
        scheduleCopyState.push({
          day: '',
          since: '',
          to: '',
        });
        setScheduleCopy({ ...scheduleCopy, schedule: scheduleCopyState });
      }
    },
    deleteDaytoSchedule = (indexS, type) => {
      if (type === 'schedule') {
        commission.schedule.splice(indexS, 1);
        setCommission({ ...commission, schedule: commission.schedule });
      } else if (type === 'copy') {
        scheduleCopy.schedule.splice(indexS, 1);
        setScheduleCopy({ ...scheduleCopy, schedule: scheduleCopy.schedule });
      }
    };

  const getMentorOrHelperName = (id) => {
    const mentor = mentorList.find((mentor) => mentor._id === id);
    return mentor ? `${mentor?.firstName} ${mentor?.lastName}` : '';
  };

  const getPlans = async () => {
    try {
      const { data } = await clientAxios(`/planes/`);
      setPlans(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCarreers = async () => {
    try {
      const { data } = await clientAxios(`/carreers/`);
      setCarreers(data);
    } catch (error) {
      notify.show('Hubo un inconveniente recuperando carreras', 'error');
    }
  };

  useEffect(() => {
    getCarreers();
  }, []);

  useEffect(() => {
    if (commission) {
      getPlans();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {!user ||
        (user && user.level < 3 && (
          <Col className="py-3 bg-light rounded shadow">
            <span className="lead">Configuración de la comisión</span>
            <CampusEnrollment
              show={showCampusEnrollment}
              onHide={() => setShowCampusEnrollment(!showCampusEnrollment)}
              updateCommission={commission}
              enrollToCourse={enrollToCourse}
            />
            <Button
              className="ml-2"
              variant="info"
              type="text"
              onClick={() => setShowCampusEnrollment(true)}
              disabled={!commission.students}
            >
              <i className="fas fa-chevron-circle-up mr-2" />
              Agregar Alumnos al Campus
            </Button>
            <Form onSubmit={updateCommission}>
              <Form.Group>
                <Form.Label>Curso</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="course"
                  value={commission.course}
                  as="select"
                  required
                >
                  <option value="">Seleccione un curso...</option>
                  {availableCourses.map((i, a) => (
                    <option key={a}>{i}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Carrera</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="carreer"
                  value={
                    commission?.carreer
                      ? commission?.carreer
                      : commission &&
                        Object.keys(commission).length === 0 &&
                        carreers?.length > 0
                      ? carreers[0]?._id
                      : ''
                  }
                  as="select"
                >
                  <option value="">Seleccione una carrera...</option>
                  {carreers?.map((carreer, index) => (
                    <option key={`${index}carreer`} value={carreer?._id}>
                      {carreer?.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Modalidad</Form.Label>
                <Form.Control
                  as="select"
                  name="mode"
                  value={commission?.mode || 'Online'}
                  onChange={handleChange}
                  required
                >
                  <option value="Online">Online</option>
                  <option value="Presencial">Presencial</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label> Descripción </Form.Label>
                <QuillEditor
                  onChange={handleQuillEdit}
                  value={commission.courseSummary}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Mentor a cargo</Form.Label>
                <Autocomplete
                  name="mentor"
                  dataSourge={mentorList}
                  commission={commission}
                  setCommission={setCommission}
                  setChanges={setChanges}
                  value={getMentorOrHelperName(commission.mentor)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tutor</Form.Label>
                <Autocomplete
                  name="helper"
                  dataSourge={mentorList}
                  commission={commission}
                  setCommission={setCommission}
                  setChanges={setChanges}
                  value={getMentorOrHelperName(commission.helper)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Plan de Pago Carrera</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  autoComplete="on"
                  name="plan"
                  value={commission?.plan || ''}
                  as="select"
                  required
                >
                  <option value="" hidden>
                    Seleccione un plan...
                  </option>
                  {plans?.map((plan, index) => {
                    if (!plan?.specialPlan) {
                      return (
                        <option
                          key={plan?._id}
                          value={plan?._id}
                          selected={commission?.plan === plan?._id}
                        >
                          {plan?.name} {plan?.description}
                        </option>
                      );
                    }
                    return null;
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Plan de Pago Curso individual</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  autoComplete="on"
                  name="planCourseSingle"
                  value={commission?.planCourseSingle || ''}
                  as="select"
                >
                  <option value="" hidden>
                    Seleccione un plan...
                  </option>
                  {plans?.map((plan, index) => {
                    if (!plan?.specialPlan) {
                      return (
                        <option key={index} value={plan._id}>
                          {plan?.name} {plan?.description}
                        </option>
                      );
                    }
                    return <></>;
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Canal de Slack</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="slackChannel"
                  value={commission.slackChannel}
                  type="text"
                ></Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>Activo/Finalizada</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="isActive"
                  value={commission.isActive}
                  as="select"
                >
                  <option value="" hidden>
                    Seleccione la actividad de esta comisión...
                  </option>
                  <option value="active">Activo</option>
                  <option value="done">Finalizada</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Publicada</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="published"
                  value={
                    commission?.published === undefined
                      ? ''
                      : commission.published
                  }
                  as="select"
                >
                  <option value="" hidden>
                    Seleccione la publicación de esta comisión...
                  </option>
                  <option value={true}>Publicada</option>
                  <option value={false}>No publicada</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Fecha de inicio</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Fecha de inicio"
                  value={
                    commission?.initialDate &&
                    moment(commission.initialDate, 'YYYY-MM-DD').format(
                      'YYYY-MM-DD'
                    )
                  }
                  defaultValue={commission?.initialDate || ''}
                  name="initialDate"
                  onChange={handleChange}
                />
              </Form.Group>
              <Container>
                {commission.schedule && commission.schedule.length !== 0
                  ? commission.schedule.map((day, index) => (
                      <Row key={index}>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Día</Form.Label>
                            <Form.Control
                              onChange={(e) =>
                                handleChange(e, index, 'schedule')
                              }
                              name="day"
                              value={day.day}
                              as="select"
                              required
                            >
                              <option hidden value="">
                                Elige un día
                              </option>
                              <option value="monday">Lunes</option>
                              <option value="tuesday">Martes</option>
                              <option value="wednesday">Miercoles</option>
                              <option value="thursday">Jueves</option>
                              <option value="friday">Viernes</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Horario Inicio</Form.Label>
                            <Form.Control
                              onChange={(e) =>
                                handleChange(e, index, 'schedule')
                              }
                              name="since"
                              value={day.since}
                              type="time"
                              max={day.to}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Horario Final</Form.Label>
                            <Form.Control
                              onChange={(e) =>
                                handleChange(e, index, 'schedule')
                              }
                              name="to"
                              value={day.to}
                              type="time"
                              min={day.since}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <div className="d-flex align-items-center h-100">
                            <FontAwesomeIcon
                              variant="info"
                              icon={faPlusCircle}
                              size="2x"
                              style={{ cursor: 'pointer', color: '#138496' }}
                              className="border-rounded"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Añadir día"
                              onClick={() => addDaytoSchedule('schedule')}
                            >
                              +
                            </FontAwesomeIcon>
                            {index >= 1 && (
                              <FontAwesomeIcon
                                variant="info"
                                icon={faMinusCircle}
                                size="2x"
                                style={{ cursor: 'pointer', color: '#138496' }}
                                className="border-rounded ml-3"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Borrar día"
                                onClick={() =>
                                  deleteDaytoSchedule(index, 'schedule')
                                }
                              >
                                –
                              </FontAwesomeIcon>
                            )}
                          </div>
                        </Col>
                      </Row>
                    ))
                  : scheduleCopy.schedule.map((day, index) => (
                      <Row key={index}>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Día</Form.Label>
                            <Form.Control
                              onChange={(e) => handleChange(e, index, 'copy')}
                              name="day"
                              value={day.day}
                              as="select"
                              required
                            >
                              <option hidden value="">
                                Elige un día
                              </option>
                              <option value="monday">Lunes</option>
                              <option value="tuesday">Martes</option>
                              <option value="wednesday">Miercoles</option>
                              <option value="thursday">Jueves</option>
                              <option value="friday">Viernes</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Horario Inicio</Form.Label>
                            <Form.Control
                              onChange={(e) => handleChange(e, index, 'copy')}
                              name="since"
                              value={day.since}
                              type="time"
                              max={day.to}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label>Horario Final</Form.Label>
                            <Form.Control
                              onChange={(e) => handleChange(e, index, 'copy')}
                              name="to"
                              value={day.to}
                              type="time"
                              min={day.since}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <div className="d-flex align-items-center h-100">
                            <FontAwesomeIcon
                              variant="info"
                              icon={faPlusCircle}
                              size="2x"
                              style={{ cursor: 'pointer', color: '#138496' }}
                              className="border-rounded"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Añadir día"
                              onClick={() => addDaytoSchedule('copy')}
                            >
                              +
                            </FontAwesomeIcon>
                            {index >= 1 ? (
                              <FontAwesomeIcon
                                variant="info"
                                icon={faMinusCircle}
                                size="2x"
                                style={{ cursor: 'pointer', color: '#138496' }}
                                className="border-rounded ml-3"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Borrar día"
                                onClick={() =>
                                  deleteDaytoSchedule(index, 'copy')
                                }
                              >
                                –
                              </FontAwesomeIcon>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Col>
                      </Row>
                    ))}
              </Container>

              <Button
                variant="info"
                type="submit"
                disabled={!commission.students}
              >
                <i className="fas fa-chevron-circle-up mr-2" />
                Actualizar datos
              </Button>
            </Form>
          </Col>
        ))}
    </>
  );
}
