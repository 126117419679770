import React, { useEffect, useState } from 'react';
import {
  faTimesCircle,
  faPlusCircle,
  faClipboard,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import {
  Modal,
  Button,
  Spinner,
  Form,
  InputGroup,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notify } from 'react-notify-toast';
import clientAxios from '../../../../clients/clientAxios';

export default function CertificateIssuer({ show, onHide, student }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputCertificateUrl, setInputCertificateUrl] = useState(
    student.certificates
  );
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(true);

  const createCertificate = (index) => {
    const filterName = student.firstname.trim();
    const filterSurname = student.surname.trim();
    if (!student.course.length === 0) {
      return;
    }
    let courseName;
    if (
      student.course[index] === 'FullStack - Corto (6 meses)' ||
      student.course[index] === 'FullStack - Online (6 meses)'
    ) {
      courseName = 'Fullstack Web Developer';
    } else {
      courseName = student.course[index] || '';
    }
    const classroom = student.classroom[index] || '';
    const classroomFullData = student.classroomData?.find(
      (element) => element?.classroom === student?.classroom[index]
    );
    const certificateData = {
      userEmail: student.email,
      userName: filterName,
      userLastName: filterSurname,
      userEmail: student.email,
      dateTo: new Date(),
      courseName: courseName,
      classroom,
      classroomId: classroomFullData?._id,
      qualitySealCert: true,
      studentId: student._id,
    };

    const studentCertificatePayload = {
      newCertificate: certificateData,
      studentId: student._id,
      studentCertificates: student?.certificates,
      clasroomIndex: index,
    };

    setIsLoading(true);
    clientAxios.post(`/certificate/`, studentCertificatePayload).then(
      ({ data }) => {
        if (data?.response && data.response.success) {
          const certificate = inputCertificateUrl || [];
          certificate[index] = { url: data.response.certificateUrl };
          setInputCertificateUrl(certificate);
          setIsLoading(false);
          notify.show('Certificado Creado!', 'success');
        }
      },
      (err) => {
        setIsLoading(false);
        if (err.response.data.message)
          notify.show(err.response.data.message, 'error');
        else notify.show(err.message, 'error');
        onHide();
      }
    );
  };

  const copyToClipboard = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        notify.show('Se ha copiado al porta papeles', 'success');
      })
      .catch((error) => {
        notify.show(error, 'error');
      });
  };

  /* Getting data from database */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, student]);

  const reSendEmail = async (certificate, index) => {
    try {
      setIsLoadingSendEmail(false);
      const studentDataForEmail = {
        userName: student?.name,
        courseName: student?.course[index],
        userEmail: student?.email,
        certificateUrl: certificate?.url,
      };
      await clientAxios.post('certificate/email', studentDataForEmail);
      notify.show('Se ha enviado el email con exito', 'success');
    } catch (error) {
      notify.show('Ha ocurrido un error al intentar enviar el email.', 'error');
    } finally {
      setIsLoadingSendEmail(true);
    }
  };

  return (
    <Modal
      scrollable
      size='xl'
      dialogClassName='modal-90w'
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Crear Certificado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Seleccione una comisión:</p>
        <div>
          {student.classroom.map((classroom, index) => (
            <div>
              {inputCertificateUrl && inputCertificateUrl[index]?.url ? (
                <div>
                  <div>
                    <InputGroup className='mb-5 mt-5'>
                      <p className='mr-2'>{classroom} - </p>
                      <Form.Control
                        name='certificateUrl'
                        value={inputCertificateUrl[index].url}
                        as='textarea'
                        required
                        type='text'
                      />
                    </InputGroup>
                  </div>
                  <div className='d-flex flex-row-reverse'>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tosoltip'>
                          <strong>Reenviar email</strong>
                        </Tooltip>
                      }
                    >
                      <Button
                        variant='primary'
                        disabled={!isLoadingSendEmail}
                        onClick={() =>
                          reSendEmail(inputCertificateUrl[index], index)
                        }
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faEnvelope} bounce />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tosoltip'>
                          <strong>Copiar al porta papeles</strong>
                        </Tooltip>
                      }
                    >
                      <Button
                        variant='primary'
                        size='sm'
                        className='mx-3'
                        onClick={() =>
                          copyToClipboard(inputCertificateUrl[index].url)
                        }
                      >
                        <FontAwesomeIcon icon={faClipboard} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              ) : (
                <Button
                  className='mb-5'
                  variant='primary'
                  onClick={() => createCertificate(index)}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> Agregar certificado -
                  Comision {classroom}
                </Button>
              )}
            </div>
          ))}
          {isLoading && (
            <div className='mt-5'>
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
              <span className='sr-only'>Loading...</span>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={onHide}>
          <FontAwesomeIcon icon={faTimesCircle} /> Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
