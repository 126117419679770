// Importando librerías externas
import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  ListGroup,
  Spinner,
} from 'react-bootstrap';
import { UserContext } from '../../Context/UserContext';
import './searchbar.css';
import clientAxios from '../../clients/clientAxios';
import { searchClassroomByName } from '../../helpers/searchClassRoomByName';

export default function Selector({
  commissionSelected,
  commission,
  confirmDelete,
  addComission,
  crList,
  copyMails,
  activeDownFilter,
  setActiveDownFilter,
  crListLoading,
  setCrListLoading,
  setCommission,
  getAllClassrooms,
}) {
  const userLevel = useContext(UserContext);
  const [searchValue, setSearchValue] = useState('');
  const [viewAllClassrooms, setViewAllClassrooms] = useState(false);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setSearchValue(event?.target?.value);
    if (event?.target?.value.length >= 2) {
      setSuggestionsVisible(true);
    } else {
      setSuggestionsVisible(false);
    }
  };

  const handleCommission = async (classroomId) => {
    try {
      setIsLoading(true);
      const { data } = await clientAxios(
        `/classroom/getStudentsInClassroom/${classroomId}`
      );
      setCommission({
        ...data.data[0],
        mentor: data.data[0].mentor,
        helper: data.data[0].helper,
        timeFrom: data.data[0].timeFrom,
        timeTo: data.data[0].timeTo,
        course: data.data[0].course,
        schedule: data.data[0].schedule,
        isActive: data.data[0].isActive ? data.data[0].isActive : '',
        students: data.data[0].students,
        courseSummary: data.data[0].courseSummary,
        plan: data.data[0].plan,
        slackChannel: data.data[0].slackChannel
          ? data.data[0].slackChannel
          : '',
        _id: classroomId,
        published: data.data[0]?.published,
        initialDate: data.data[0]?.initialDate || '',
      });
      setSuggestionsVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearInput = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (searchValue.length >= 2) {
      searchClassroomByName(setIsLoading, setSuggestions, searchValue);
    }
  }, [searchValue]);

  return (
    <Row>
      <div className='container d-flex bg-light rounded shadow p-3 align-items-center'>
        <Col xs={6} sm={6} md={5} lg={5}>
          <Row>
            <InputGroup className='m-0 p-0 pb-1'>
              <InputGroup.Prepend>
                <InputGroup.Text>Activo/Finalizada</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as='select'
                name='commissionChangedReason'
                onChange={(e) => {
                  setActiveDownFilter(e.target.value);
                  setCrListLoading(true);
                }}
                value={activeDownFilter}
              >
                <option value='active'>Activo</option>
                <option value='down'>Finalizada</option>
                <option value=''>Todos</option>
              </Form.Control>
            </InputGroup>
          </Row>
          <Row className='w-100'>
            <InputGroup>
              <InputGroup.Append>
                <InputGroup.Text id='basic-addon2'>
                  <i className='fa fa-search'></i>
                </InputGroup.Text>
              </InputGroup.Append>
              <div className='search-bar-container'>
                <Form.Control
                  type='text'
                  placeholder='Buscar comision...'
                  value={searchValue}
                  onChange={handleChange}
                />
                {suggestionsVisible && (
                  <ListGroup className='suggestions-list'>
                    <ListGroup.Item>
                      {isLoading ? (
                        <Spinner
                          animation='border'
                          role='status'
                          size='sm'
                        ></Spinner>
                      ) : (
                        <div
                          onClick={() => {
                            setSearchValue(suggestions?.classroom);
                            handleCommission(suggestions?._id);
                          }}
                        >
                          {suggestions?.classroom}
                        </div>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                )}
              </div>
              <div>
                <Button
                  block
                  onClick={() => {
                    getAllClassrooms();
                    setViewAllClassrooms(true);
                    handleClearInput();
                  }}
                >
                  Ver todas
                </Button>
              </div>
            </InputGroup>
            {viewAllClassrooms && (
              <InputGroup className='mt-1 p-0'>
                <InputGroup.Prepend>
                  <InputGroup.Text>Comisión:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as='select'
                  name='commissionSelected'
                  autoComplete='on'
                  onChange={(obj) => commissionSelected(obj)}
                  disabled={crListLoading || crList.length === 0}
                >
                  {crListLoading || crList.length === 0 ? (
                    <option hidden>Cargando comisiones</option>
                  ) : (
                    <option value='' hidden>
                      Seleccione una comisión...
                    </option>
                  )}
                  {crList.map((i, a) => (
                    <option key={a} value={i._id} name={i.classroom}>
                      {i.classroom}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
            )}
          </Row>
        </Col>
        <Col />
        <Col xs={6} sm={6} md={7} lg={7}>
          <Button
            className='mr-2 mb-0'
            variant='info'
            onClick={() => copyMails()}
            disabled={!commission.students}
          >
            <i className='fas fa-plus-circle mr-2' />
            Copiar E-mails
          </Button>
          {!userLevel ||
            (userLevel && userLevel.level < 3 && (
              <Button
                disabled={!commission.students}
                className='mr-2 mb-0'
                variant='danger'
                onClick={() => confirmDelete()}
              >
                <i className='fas fa-times-circle mr-2' />
                Eliminar comisión
              </Button>
            ))}
          {!userLevel ||
            (userLevel && userLevel.level < 3 && (
              <Button
                className='mb-0'
                variant='success'
                onClick={() => addComission()}
              >
                <i className='fas fa-plus-circle mr-2' />
                Agregar comisión
              </Button>
            ))}
        </Col>
      </div>
    </Row>
  );
}
