import React from 'react';
import { Tab, Tabs, Col } from 'react-bootstrap';
import Info from './Info';
import PromoteStudents from './PromoteStudents';

const TabbedInterface = ({ mentorList, commission, setCommission }) => {
  return (
    <Col xs={12} md={6} className="py-3">
      <Tabs defaultActiveKey="info" id="commission-tabs">
        <Tab eventKey="info" title="Información">
          <Info 
            mentorList={mentorList}
            commission={commission}
            setCommission={setCommission}
          />
        </Tab>
        <Tab eventKey="other" title="Migrar de Classroom">
          <div className="p-4 bg-light rounded shadow">
            <PromoteStudents commission={commission} />
          </div>
        </Tab>
      </Tabs>
    </Col>
  );
};

export default TabbedInterface;